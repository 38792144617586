import React from 'react';
import './Privacy.css'; // Create this CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>
        <strong>EarnFlix</strong> is committed to protecting the privacy of its users. This Privacy Policy explains how we collect, use, and safeguard personal information.
      </p>

      <section>
        <h2>Information Collection</h2>
        <p>We collect the following personal information:</p>
        <ul>
          <li>Name and email address upon registration</li>
          <li>Device information (e.g., device type, operating system)</li>
        </ul>
      </section>

      <section>
        <h2>Purpose of Collection</h2>
        <p>We use the collected information to:</p>
        <ul>
          <li>Provide personalized experiences</li>
          <li>Enhance and improve our services</li>
        </ul>
      </section>

      <section>
        <h2>Data Security</h2>
        <p>
          EarnFlix employs industry-standard security measures to protect personal information from unauthorized access, disclosure, alteration, or destruction.
        </p>
      </section>

      <section>
        <h2>Policy Updates</h2>
        <p>
          We reserve the right to modify this Privacy Policy at any time. Changes will be effective immediately upon posting the updated Policy on this page.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          For data protection inquiries, concerns, or to exercise your data subject rights, please contact:
        </p>
        <p><strong>Muhammad Ali</strong><br />
          <a href="mailto:mohamedali116500@gmail.com">mohamedali116500@gmail.com</a>
        </p>
      </section>

      <p>By using our services, you acknowledge that you have read, understood, and agree to this Privacy Policy.</p>
    </div>
  );
};

export default PrivacyPolicy;
