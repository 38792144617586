import React from 'react';
import './AboutUs.css'; // Create this CSS file for styling

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>About Us</h1>
      <p>
        Welcome to <strong>EarnFlix</strong>, your premier destination for high-quality content from YouTube. Our mission is to curate and showcase the best videos from various categories, including lifestyle, education, and entertainment, in one convenient platform.
      </p>

      <section>
        <h2>Our Vision</h2>
        <p>
          At EarnFlix, we envision a world where everyone can easily access and enjoy the most informative, engaging, and entertaining content available on YouTube, without feeling overwhelmed or fatigued.
        </p>
      </section>

      <section>
        <h2>What We Offer</h2>
        <p>
          EarnFlix uses a unique approach to filter out high-quality content from YouTube, utilizing the YouTube Data API v3 to fetch and display the best videos. Our platform is designed to prevent content fatigue, ensuring that you always find something new and interesting to watch.
        </p>
      </section>

      <section>
        <h2>Our Commitment</h2>
        <p>
          We are committed to ensuring the security and privacy of our users' personal information. EarnFlix employs industry-leading security measures to safeguard user data and offers transparent policies regarding data collection and usage.
        </p>
      </section>

      <section>
        <h2>Join Our Community</h2>
        <p>
          Join EarnFlix today and discover a world of high-quality content from YouTube, curated just for you! Our platform is perfect for anyone looking to learn, grow, or simply be entertained, without the risk of content fatigue. <strong>Sign up now</strong> and start exploring!
        </p>
      </section>
    </div>
  );
};

export default AboutUs;
